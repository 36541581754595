<template>
  <div>
    <v-dialog
      v-model="showModal"
      max-width="500"
    >
      <v-form>
        <v-card>
          <v-card-title>
            Create New Project

            <v-spacer />

            <v-icon
              aria-label="Close"
              @click="showModal = false"
            >
              mdi-close
            </v-icon>
          </v-card-title>

          <v-card-text class="text-body-1 text-center">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="name"
                    label="Project Name"
                    hint="Project's name"
                    outlined
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="address"
                    label="Address"
                    hint="Project Location"
                    outlined
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="projectManager"
                    label="Project Manager's Name"
                    hint="Project manager's name"
                    outlined
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="red darken-3"
              depressed
              default
              @click="cancel()"
            >
              Cancel
            </v-btn>
            <v-spacer />
            <v-btn
              class="mt-2"
              color="#FF3700"
              depressed
              default
              @click="submit()"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
  import axios from 'src/app-axios';
  import constants from 'src/constants';

  export default {
    name: 'EditProject',
    props: {
      editvalues: {
        type: Object,
        required: true,
      },
    },
    data: () => ({
      id: '',
      name: null,
      projectManager: null,
      address: null,
      showModal: true,
    }),

    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },

    mounted () {
      this.fillDetails();
    },

    methods: {
      cancel () {
        this.showModal = false;
      },

      generateFormData () {
        const formData = new FormData();
        formData.append('_method', 'PUT');
        formData.append('name', this.name);
        formData.append('project_manager', this.projectManager);
        formData.append('address', this.address);

        return formData;
      },

      fillDetails () {
        this.id = this.editvalues.id;
        this.name = this.editvalues.name;
        this.address = this.editvalues.address;
        this.projectManager = this.editvalues.project_manager;
      },

      submit () {
        const formData = this.generateFormData();
        const uri = 'api/swift-checkin/projects/' + this.id;

        axios.post(uri, formData,
        ).then(response => {
          this.showModal = false;
          this.$emit('success');
        }).catch(() => {
          this.showModal = false;
          this.$store.dispatch('alert/onAlert', {
            message: 'Something went wrong in updating project.',
            type: constants.ALERT_TYPE_ERROR,
          });
        });
      },
    },
  };
</script>
