<template>
  <div>
    <v-dialog
      v-model="showModal"
      max-width="500"
    >
      <v-form>
        <v-card>
          <v-card-title>
            Create New Project

            <v-spacer />

            <v-icon
              aria-label="Close"
              @click="showModal = false"
            >
              mdi-close
            </v-icon>
          </v-card-title>

          <v-card-text class="text-body-1 text-center">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="createData.name"
                    label="Project Name"
                    hint="Project's name"
                    outlined
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="createData.address"
                    label="Address"
                    hint="Project Location"
                    outlined
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="createData.project_manager"
                    label="Project Manager's Name"
                    hint="Project manager's name"
                    outlined
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="red darken-3"
              depressed
              default
              @click="cancel()"
            >
              Cancel
            </v-btn>
            <v-spacer />
            <v-btn
              class="mt-2"
              color="#FF3700"
              depressed
              default
              @click="submit()"
            >
              Create
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
  import axios from 'src/app-axios';
  import constants from 'src/constants';

  export default {
    data: () => ({
      createData: {
        name: null,
        project_manager: null,
        address: null,
      },
      showModal: true,
    }),

    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },

    methods: {
      cancel () {
        this.showModal = false;
      },

      submit () {
        axios.post('api/swift-checkin/projects', this.createData,
        ).then(response => {
          this.showModal = false;
          this.$emit('success');
        }).catch(() => {
          this.showModal = false;
          this.$store.dispatch('alert/onAlert', {
            message: 'Something went wrong in creating new project.',
            type: constants.ALERT_TYPE_ERROR,
          });
        });
      },
    },
  };
</script>
