<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <create-project
      v-if="showCreate"
      @closed="showCreate = false"
      @success="createSuccess"
    />
    <edit-project
      v-if="showEdit"
      :editvalues="editProjectInfo"
      @closed="showEdit = false"
      @success="editSuccess"
    />
    <delete-project
      v-if="showDelete"
      :title="'Delete Project?'"
      @closed="showDelete = false"
      @confirmed="deleteConfirmed()"
    />
    <base-material-card
      icon="mdi-clipboard-text"
      title="Projects"
      class="px-5 py-3"
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col
              cols="12"
              lg="4"
            >
              <v-text-field
                v-model="search"
                label="Search"
                append-icon="mdi-magnify"
                outlined
              />
            </v-col>
            <v-col
              cols="12"
              lg="8"
              class="text-right"
            >
              <v-btn
                color="#FF3700"
                @click="newProject()"
              >
                New Project
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :items-per-page="projectsTable.itemsPerPage"
          :headers="projectsTable.headers"
          :items="projects"
          :search="search"
          class="elevation-1"
        >
          <template
            v-slot:body="{ items }"
          >
            <tr
              v-for="(project, index) in items"
              :key="index"
              class="clickable"
              @click="editProject(project.id)"
            >
              <td>{{ project.name }}</td>
              <td>{{ project.address }}</td>
              <td>
                {{ project.project_manager }}
              </td>
              <td>
                {{ project.updated_at | diffHumans }}
              </td>
              <td>
                <v-col>
                  <v-btn
                    class="mt-n2"
                    elevation="1"
                    fab
                    x-small
                    icon
                    color="red"
                    @click.stop
                    @click="deleteProject(project.id, project.name)"
                  >
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </v-col>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </base-material-card>

    <centre-spinner
      :loading="loading"
    />
  </v-container>
</template>

<script>
  import axios from 'src/app-axios';
  import constants from 'src/constants';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import DeleteDialog from 'src/views/dashboard/component/DeleteDialog';
  import CreateProject from './CreateProject';
  import EditProject from './EditProject';
  import moment from 'moment';

  export default {

    name: 'CheckinProjects',

    components: {
      'centre-spinner': spinner,
      'delete-project': DeleteDialog,
      'create-project': CreateProject,
      'edit-project': EditProject,
    },

    filters: {
      diffHumans (val) {
        return moment(val).fromNow();
      },
      convertStringToLocalDatetime (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
    },

    data: () => ({
      projects: [],
      projectsTable: {
        headers: [
          { text: 'Name', align: 'start', value: 'name' },
          { text: 'Address', value: 'address' },
          { text: 'Project Manager', value: 'project_manager' },
          { text: 'Last Updated', value: 'updated_at' },
          { text: 'Delete', value: 'delete' },
        ],
        itemsPerPage: 10,
      },
      search: '',
      loading: false,
      deleteName: '',
      deleteId: '',
      showCreate: false,
      showDelete: false,
      showEdit: false,
      editProjectInfo: {},
    }),

    created () {
      this.loading = true;
      this.getAllProjects();
    },

    methods: {
      createSuccess () {
        this.getAllProjects();
        this.$store.dispatch('alert/onAlert', {
          message: 'New Project Added!',
          type: constants.ALERT_TYPE_SUCCESS,
        });
      },
      editProject (projectId) {
        this.editProjectInfo = this.projects.find(
          project => project.id === projectId,
        );
        this.showEdit = true;
      },
      editSuccess () {
        this.getAllProjects();
        this.$store.dispatch('alert/onAlert', {
          message: 'Project Details Updated!',
          type: constants.ALERT_TYPE_SUCCESS,
        });
      },
      deleteProject (projectId, projectName) {
        this.showDelete = true;
        this.deleteId = projectId;
        this.deleteName = projectName;
      },
      deleteConfirmed () {
        axios.delete('api/swift-checkin/projects/' + this.deleteId).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Project ' + this.deleteName + ' removed!',
            type: constants.ALERT_TYPE_SUCCESS,
          });
          this.showDelete = false;
          this.getAllProjects();
        });
      },
      getAllProjects () {
        axios.get('api/swift-checkin/projects').then(response => {
          this.projects = response.data;
          this.loading = false;
        }).catch(() => {
          this.loading = false;
          this.$store.dispatch('alert/onAlert', {
            message: 'Something went wrong in getting projects.',
            type: constants.ALERT_TYPE_ERROR,
          });
        });
      },
      newProject () {
        this.showCreate = true;
      },
    },
  };
</script>
<style scoped>
  .clickable {
    cursor: pointer;
  }
</style>
